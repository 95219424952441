import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dalle-image"
export default class extends Controller {

    static values = { image_data: Object, imageData: Object, isNew: Boolean };

    static classes = [ "loading" ];
  connect() {
    //console.log("dalle_image_controller connected", this.imageDataValue, this.image_dataValue)
      // set the browser height from local storage

  }
}
