import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dalle-prompt-inspector"
export default class extends Controller {
  static targets = ["promptText", "promptForm", "promptInput", "promptSubmit", "promptCancel", "promptDelete", "promptDeleteConfirm"];
  connect() {
  }

  promptClicked(event) {
    console.log("promptClicked", event)
    this.promptTextTarget.contentEditable = true;
  }
}
