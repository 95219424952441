import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { tabid: String };

  initialize() {
    this.loadActiveTabs();
  }

  connect() {
    this.element.addEventListener("click", (event) => this.saveActiveTab(event));
  }

  disconnect() {
    this.element.removeEventListener("click", (event) => this.saveActiveTab(event));
  }

  saveActiveTab(event) {
    if (event.target.classList.contains("nav-link")) {
      const key = this.generateStorageKey(event.target.id);
      localStorage.setItem(key, event.target.id);
      this.updateURL(key, event.target.id);
      console.log(event.target)

    }
  }

  loadActiveTabs() {
    const identifier = this.tabidValue;  // Corrected this line
    const activeTabId = this.getTabFromLocalStorage(identifier) || this.getTabFromURL(identifier);
    if (activeTabId) {
      document.getElementById(activeTabId).click();
    } else {
      // Find the first tab and set it as active
      const firstTab = this.element.querySelector('.nav-link');
      if (firstTab) {
        firstTab.click();
      }
    }

  }

  generateStorageKey(tabId) {
    return `${this.tabidValue}-tab`;  // Corrected this line
  }

  getTabFromLocalStorage(identifier) {
    return localStorage.getItem(this.generateStorageKey(identifier));
  }

  getTabFromURL(identifier) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(this.generateStorageKey(identifier));
  }

  updateURL(key, activeTabId) {
    const url = new URL(window.location.href);
    url.searchParams.set(key, activeTabId);
    window.history.pushState({}, '', url);
  }
}
