import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-load"
export default class extends Controller {
  connect() {
    console.log("Image loading", this.element)
   //this.show();
  }

  show() {
    console.log("Image loaded", this.element)
    // Remove old content
    window.te = this.element;
    const oldContent = this.element.previousElementSibling;
    if (oldContent) oldContent.classList.remove("show");

    // Show new content
    //this.element.style.display = "";
    this.element.classList.add("show");
  }

}
