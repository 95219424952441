import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="slack-user-selector"

// This controller is used to select a user from a list of users
// All uses are initially loaded as straight HTML
// This controller indexes the users found in the html
// This controller filters the list of users based on input into a search field
export default class extends Controller {
  connect() {
  }
}
