import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="chat-list-item"
export default class extends Controller {
    static targets = ["aiTitle", "aiSummary", "aiPullQuote"];

    connect() {
        //console.log("Hello, chat-list-item!", this.element)
    }

    load_conversation(event) {
        console.log("load_conversation!", event.currentTarget, this.aiElement)
        event.preventDefault()
        //window.location.href = event.currentTarget.dataset.url
    }

    refreshElement(event) {
        console.log("refresh_element!", event.currentTarget, event.params, this.aiElement)
        event.preventDefault()

        // Disable the button
        // Get the reference to the button
        const button = event.currentTarget;
        const button_text = button.textContent;
        // Disable the button
        button.disabled = true;
        button.textContent = "Loading...";

        let textElement = null

        if (event.params.aiElement === "title") {
            textElement = this.aiTitleTarget;
        } else if (event.params.aiElement === "pull_quote") {
            textElement = this.aiPullQuoteTarget;
        } else if (event.params.aiElement === "summary") {
            textElement = this.aiSummaryTarget;
        }

        if (textElement !== null) {
            //const textElement = this.aiElementTarget;
            let words = textElement.textContent.split(' ');

// Calculate the interval duration based on the number of words
            let duration = 1000 / words.length;

// Start the animation to remove words one by one
            let intervalId = setInterval(() => {
                words.pop();
                textElement.textContent = words.join(' ');

                if (words.length === 0) {
                    clearInterval(intervalId);
                  textElement.textContent = "Loading...";
                }
            }, duration); // timing based on word count
        }

        // start the content update
        fetch(event.currentTarget.dataset.url,).then((response) => {
            //event.currentTarget.textContent = "Loading " + response.text();
            button.disabled = false;
            button.textContent = button_text;
        })
    }


}
