import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ai-content"
export default class extends Controller {
  static targets = ["content"]
  connect() {
    //this.element.addEventListener('click', this.clicked.bind(this));
  }

  disconnect() {
    //this.element.removeEventListener('click', this.clicked.bind(this));
  }

  regenerate(event) {
    console.log("regenerate", event)
    event.preventDefault();
    const button = event.currentTarget;
    button.disabled = true;
    this.contentTarget.innerText = "Regenerating...";
    fetch(event.currentTarget.dataset.url)
  }

  isClicked = false;
  clicked(event) {
    console.log("click", event)
    if(this.isClicked === false){
      //this.isClicked = true;
      this.toggleContentVisibility(event)

    }
  }
  toggleContentVisibility(event) {
    console.log(event, event.target, event.target.parentElement, this.element)
    // if (event.target !== this.element && event.target.parentElement !== this.element) {
    //   return;
    // }
    //this.element.removeEventListener('click', this.toggleContentVisibility.bind(this));
    // Get the first 'content' controller that is a child of this 'ai-content' controller
    const contentControllerElement = this.element.querySelector('[data-controller="content"]')
    const contentController = this.application.getControllerForElementAndIdentifier(contentControllerElement, "content")
    contentController.toggleVisibility()
  }
}
