// app/javascript/controllers/scroll_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];
  static values = { speed: Number }

  connect() {
    this.scrollAmount = 0;
    this.speedValue = this.hasSpeedValue ? this.speedValue : 1; // Default speed if not specified
    this.scrollContent();
  }

  scrollContent() {
    const scrollStep = () => {
      // Increment scrollAmount by speedValue
      this.scrollAmount += this.speedValue;

      // If scrollAmount exceeds the scrollable area, reset to 0
      if (this.scrollAmount >= this.contentTarget.scrollHeight - this.contentTarget.clientHeight) {
        this.scrollAmount = 0; // Reset scroll to the top
      }

      // Perform the scroll
      this.contentTarget.scrollTo(0, this.scrollAmount);
      requestAnimationFrame(scrollStep);
    };

    requestAnimationFrame(scrollStep);
  }
}
