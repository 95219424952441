import {FetchRequest} from "@rails/request.js";



// app/javascript/controllers/participant_group_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "button"];

  async add(event) {
    event.preventDefault();
    console.log(this.buttonTarget.dataset)
    const groupId = this.selectTarget.value;  // Get the selected team ID
    const formId = this.buttonTarget.dataset.questionId; // Get the form ID from the button's data attribute

    if (!groupId) {
      alert("Please select a group.");
      return;
    }

    const url = `/slack_survey_questions/${formId}/add_group_members`; // Construct the URL

      // Prepare the request options
      const requestOptions = {
          method: "POST",
          body: {
              group_id: groupId,
              slack_survey_question: { id: formId },
          },
          responseKind: 'turbo-stream'
      };

      // Initialize a new FetchRequest
      const request = new FetchRequest("post", url, requestOptions);


      try {
          // Perform the request and await the response
          const response = await request.perform();

          // Handle the successful response
          console.log("Participant added:", response);

          // Optional: Update the UI or provide user feedback here
          // For example, you might want to clear the selection or display a success message
      } catch (error) {
          // Handle any errors that occur during the request
          console.error("Error adding participant:", error);

          // Optional: Display an error message to the user
          alert("There was an error adding the participant. Please try again.");
      }
  }
}