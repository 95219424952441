import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="screenshotter"

export default class extends Controller {

  static targets = ["interval"];

  stream = null;
  connect() {
    this.isCapturing = false;
    this.captureInterval = null;
  }

  getOrCreateCanvas() {
    let canvas = document.getElementById('canvas');
    if (!canvas) {
      canvas = document.createElement('canvas');
      canvas.id = 'screenshot_canvas';
      // Append the canvas to the body or another specific parent element
      document.body.appendChild(canvas);
    }
    return canvas;
  }

  toggleCapture() {
    this.isCapturing = !this.isCapturing;

    if (this.isCapturing) {
      let interval = this.intervalTarget.value || 5000; // Default to 5 seconds if not set
      this.startCapture(interval);
    } else {
      this.stopCapture();
    }
  }

  startCapture(interval) {
    this.captureInterval = setInterval(() => {
      this.captureScreenshot();
    }, interval);
  }

  stopCapture() {
    clearInterval(this.captureInterval);
  }

  captureScreenshot() {
    this.canvas = this.getOrCreateCanvas();

    this.stream = navigator.mediaDevices.getDisplayMedia()
    //this.captureScreenshot()

    console.log('Capturing screenshot...');
    navigator.mediaDevices.getDisplayMedia().then(stream => {
      let track = this.stream.getVideoTracks()[0];
      let capture = new ImageCapture(track);
      capture.grabFrame().then(bitmap => {
        //track.stop();
        this.drawBitmapToCanvas(bitmap);
        this.canvasToBlob();
      });
    }).catch(e => console.log(e));
  }

  drawBitmapToCanvas(bitmap) {
    this.canvas.width = bitmap.width;
    this.canvas.height = bitmap.height;
    this.canvas.getContext('2d').drawImage(bitmap, 0, 0);
  }

  canvasToBlob() {
    this.canvas.toBlob(blob => {
      this.handleBlob(blob);
    });
  }

  handleBlob(blob) {
    // Placeholder method to handle the blob.
    // Extend this for uploading or any other actions.
    console.log('Output blob:', blob);
  }
}
