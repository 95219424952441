import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="function-list"
export default class extends Controller {
  static values = { isLoaded: Boolean, id: Number, url: String };
    static targets = [ "imageCol" ]
  connect() {
    console.log("function-list connected: Loaded?", this.isLoadedValue)
    console.log(this.urlValue)
  }

  imageClicked(event) {
    console.log(event.target)
    event.target.classList.toggle("show")
    // get the current image height and width
    // let height = this.imageColTarget.offsetHeight
    // let width = this.imageColTarget.offsetWidth
    // // apply to the parent figure
    // this.imageColTarget.style.height = height + "px"
    // this.imageColTarget.style.width = width + "px"
   fetch('/function_lists/' + this.idValue + '/regenerate?only_image=true')
  }

  imageLoaded(event) {
    console.log("imageloaded", event.target)
    event.target.classList.toggle("show")
  }
}
