import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="preload-image"
export default class extends Controller {
  static targets = ["old", "new"]

  replace() {
    this.oldTarget.replaceWith(this.newTarget)
    this.newTarget.classList.remove("d-none")
  }

}
