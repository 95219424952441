import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="audio-bolder"
export default class extends Controller {
  static targets = ["player", "text"]

  connect() {
    this.playerTarget.addEventListener("loadedmetadata", this.setup.bind(this))
    this.playerTarget.addEventListener("loadedmetadata", (event)=>{console.log("loadedmetadata", event)})
  }
  playerTargetConnected() {
    this.setup()
  }

  setup(){
    this.playerTarget.addEventListener("timeupdate", this.update.bind(this))
    this.words = this.textTarget.innerText.split(' ')
    this.textTarget.innerHTML = this.words.map((word, index) => {
      let startTime = (this.playerTarget.duration / this.words.length) * index
      return `<span data-start-time="${startTime}">${word}</span>`
    }).join(' ')

    this.textTarget.querySelectorAll('span').forEach(word => {
      word.addEventListener('click', this.seek.bind(this))
    })

  }

  update() {
    let currentTime = this.playerTarget.currentTime
    let lastBoldWord = null
    this.textTarget.querySelectorAll('span').forEach(word => {
      let startTime = parseFloat(word.dataset.startTime)
      if (currentTime >= startTime) {
        word.classList.add('fw-bold')
        lastBoldWord = word
      } else {
        word.classList.remove('fw-bold')
      }
    })
    if (lastBoldWord) {
      lastBoldWord.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
    }
  }

  seek(event) {
    let startTime = parseFloat(event.target.dataset.startTime)
    this.playerTarget.currentTime = startTime
    this.playerTarget.play()
  }

}
