import { Application } from "@hotwired/stimulus"
import Sortable from 'stimulus-sortable'
import Carousel from 'stimulus-carousel'
import Remote from 'stimulus-remote-rails'
import NestedForm from 'stimulus-rails-nested-form'

const application = Application.start()
application.register('sortable', Sortable)
application.register('carousel', Carousel)
application.register('remote', Remote)
application.register('nested-form', NestedForm)

// Configure Stimulus development experience
application.debug = true
window.Stimulus   = application

export { application }

import 'swiper/css/bundle'