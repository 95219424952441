import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="detailed-summary"
export default class extends Controller {
  static targets = [ "sourceText", "sourceUrl" ]

  connect() {
  }

   getText() {

    const urlTextExtractionPath ="/extract_text_from_url"
    const outputTextEl = this.sourceTextTarget
    const sourceUrlEl = this.sourceUrlTarget
    outputTextEl.value = "Loading..."

    // fetch the text using element2.value
    // then set the text using element.value

     fetch( urlTextExtractionPath + "?url=" + sourceUrlEl.value, { method: 'GET', }).then(
            function(response) {
                console.log(response)
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                    response.status);
                    return;
                }
                // Examine the text in the response
                response.json().then(function(data) {
                    outputTextEl.value = data.text
                    console.log(data)
                })
            }
     )

  }
}
