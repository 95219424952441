import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";

// Connects to data-controller="survey-deliver-tab"
export default class extends Controller {
  connect() {
    const tabEl = document.querySelector('#deliver')
    this.loadUserSearchPanel()
    console.log("tabEl", tabEl)
    tabEl.addEventListener('shown.bs.tab', event => {
      console.log(event.target) // newly activated tab
      console.log(event.relatedTarget) // previous active tab

    })
  }

  has_panel(){
    return this.getUserSearchPanel() != null
  }
  getUserSearchPanel() {
    return document.querySelector('#slack_user_selector')
  }

  async loadUserSearchPanel() {

    const panel = this.getUserSearchPanel()
    if (panel) {
      const url = panel.getAttribute('data-url')
      if(url==null){return}
      panel.innerHTML = "Loading..."
      //fetch(url , { headers: { accept: "text/vnd.turbo-stream.html" } })
      //post('/items/search', { responseKind: 'turbo-stream' })
      const request = new FetchRequest('get', url, { responseKind: 'turbo-stream' })
      const response = await request.perform()

    }
  }
}
