import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="content"
export default class extends Controller {
    static targets = ["panel", "versioninfo"]

    connect() {
        this.element.classList.add("collapse", "d-none")
    }

    toggleVisibility() {
        console.log("toggleVisibility", this.element)
        this.element.classList.toggle("d-none")
        this.element.classList.toggle("show")
    }
    goBack() {
        console.log("goBack!")
    }

    goForward() {
        console.log("goForward!")
    }

    refresh(event) {
        event.preventDefault();
        const button = event.currentTarget;
        button.disabled = true;
        this.versioninfoTarget.innerText = "Loading...";
        fetch(event.currentTarget.dataset.url,).then((response) => {
            return response.json(); // Parse JSON from the response
        }).then((data) => {
            console.log("data", data);
            button.disabled = false;
            this.versioninfoTarget.innerText = data.new_version_info; // Assuming new_version_info is a field in the response data
        }).catch((error) => {
            console.log('Error:', error);
        });
    }
}
