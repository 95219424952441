import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="function-call-form"
export default class extends Controller {

  rootPath = null;
  connect() {
    this.rootPath = "/function_calls/" + this.element.id.split("_").pop();
    console.log("function_call_form_controller connected", this.element.id, this.rootPath)
  }

  addParameterClicked(event) {
    event.preventDefault();
    fetch(this.rootPath + "/add_parameter")
    // const parameters = this.element.querySelector(".parameters");
    // const parameter = this.element.querySelector(".parameter");
    // const newParameter = parameter.cloneNode(true);
    // parameters.appendChild(newParameter);
  }
}
