import {Controller} from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";

// Connects to data-controller="ui-state-saver"
export default class extends Controller {
    static targets = ["cardbody"]
    static values = {uiStateId: Number, submitForm: Boolean};

    csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    connect() {
        console.log("ui-state-saver connect", this.element.id)
        if(this.submitFormValue){
            this.element.querySelectorAll('form').forEach(form => {
                form.addEventListener('change', this.submitForm.bind(this));
            });

        }

    }

    ui_save_url() {
        return "/ui_states/" + this.uiStateIdValue + ".json";
    }

    async saveUiState(key, value) {
        console.log("saveUiState", key, value)
        let data = {ui_key: key, ui_value: value}
        const url = this.ui_save_url();
        console.log("saveUiState", url, data)
        const request = new FetchRequest('PUT', url, { responseKind: 'turbo-stream', body: {ui_state: data} })
        const response = await request.perform()
        console.log("saveUiState response", response)
    }


    dragComplete(event) {
        console.log("dragComplete", event)
        const data = { ui_state: {ui_key: "cardbody", ui_value: event.detail.newPosition}};

        const request = new FetchRequest('POST', url, { responseKind: 'turbo-stream', body: { prompt: prompt } })
        request.perform().then(response => {
            console.log("dragComplete response", response)
        })

    }

    submitForm(event) {
        // Prevent the default form submission behavior
        event.preventDefault();

        // Find the form element that triggered the change event
        const form = event.target.closest('form');

        // Prepare the data to be sent in the fetch request
        const formData = new FormData(form);
        formData.append('authenticity_token', this.csrfToken);

        // Determine the URL from the form's action attribute
        const url = form.action;

        // Send the form data using fetch
        fetch(url, {
            method: 'POST',
            headers: {
                "X-CSRF-Token": this.csrfToken,
                "Accept": "application/json"
            },
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Form submitted successfully', data);
            })
            .catch(error => {
                console.error('Error submitting form:', error);
            });
    }



    toggle(event) {
        console.log("toggle", this.uiStateIdValue, event.delegateTarget.id)
        const collapsed = event.delegateTarget.classList.contains("collapsed");
        const id = event.delegateTarget.id;

        const url = "/ui_states/" + this.uiStateIdValue + ".json";
        const data = {authenticity_token: this.csrfToken, ui_state: {ui_key: id, ui_value: collapsed}};
        fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": this.csrfToken
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then((data) => {
                console.log("toggle response", data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });

    }

    trashClicked(event) {
        // turbo confirm message
        if (confirm("Are you sure you want to delete this parameter?")) {
            // delete the parameter
            //this.element.remove()
        }
    }
}
