// app/javascript/controllers/popup_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  open() {
    this.modalTarget.show() // Using Bootstrap's modal JavaScript API
  }

  close() {
    this.modalTarget.hide() // Using Bootstrap's modal JavaScript API
  }
}
