import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="general-form"
export default class extends Controller {

  static targets = [ "aForm", "modal", "testResults", 'doTestButton', 'keepTestButton', 'deleteTestButton' ]
  static outlets = [ "function-list" ]
  testViewerModal;
  csrfToken;

  functionListOutletConnected(outlet, element) {
    console.log("functionListOutletConnected")
    if (this.hasFunctionListOutlet) {
      console.log("hasOutlet: " + this.hasFunctionListOutlet)
      if(this.functionListOutlet.isLoadedValue){
        console.log("isLoaded: " + this.functionListOutlet.isLoadedValue)
        this.doTestButtonTarget.classList.remove("disabled")
        this.keepTestButtonTarget.classList.remove("disabled")
        this.deleteTestButtonTarget.classList.remove("disabled")
      }
    }
  }

  functionListsOutletDisconnected(outlet, element) {
    console.log("functionListsOutletDisconnected")
  }

  connect() {
    this.testViewerModal = new bootstrap.Modal(this.modalTarget, {backdrop:true})
    const cards = document.querySelectorAll('.card_function_list')
    this.csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content")

  }

  doTest(){
    console.log("hasOutlet: " + this.hasFunctionListOutlet)
    const cards = document.querySelectorAll('.card_function_list')
    console.log(cards)
    this.functionListOutlets.forEach(functionListOutlet => { console.log(functionListOutlet) })
    console.log(this, "hasOutlet: " + this.hasFunctionListOutlet)

  }
  save(event) {
    event.preventDefault()
    let formData = new FormData(this.aFormTarget)

    fetch(this.aFormTarget.action + ".json", {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.csrfToken
      },
      body: formData
    }).then(response => response.json())
        .then(data => {
          console.log(data);
        });
  }

  startTest(event) {
    this.save(event)
    this.testViewerModal.show()
    this.testResultsTarget.innerHTML = "Loading..."


    fetch(this.aFormTarget.action + "/test_list")
        .then(response => response.text())
        .then(data => {
          this.testResultsTarget.innerHTML = data
          //alert("Test started")
        })
  }

  hideTest(){
    this.testViewerModal.hide()
  }

  deleteTest(){
    event.preventDefault()
    let formData = new FormData(this.aFormTarget)

    fetch(this.functionListOutlet.urlValue + ".json", {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': this.csrfToken
      }
    })
    this.testViewerModal.hide();
  }

  keepTest(){
    event.preventDefault()
    fetch(this.functionListOutlet.urlValue + "/keep_test.json", {
      headers: {
        'X-CSRF-Token': this.csrfToken
      }
    })

    this.testViewerModal.hide();
  }
}
