import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="app-sidebar"
export default class extends Controller {

    static outlets = ["ui-state-saver"]

    uiStateSaverOutletConnected(outlet, element) {
        console.log("uiStateSaverOutletConnected", outlet, element)
    }

    connect() {
        //console.log("Hello, Stimulus!", this.element)
    }

    newClicked(event) {
        this.removeActive()
    }

    removeActive() {
        this.element.querySelectorAll(".active").forEach((item) => {
            item.classList.remove("active")
        })
    }

    itemClicked(event) {
        //prevent default link behavior
        //event.preventDefault()
        //console.log("itemClicked", event)
        // remove active class from all items
        this.removeActive()
        // add active class to clicked item
        event.currentTarget.classList.add("active")
        Turbo.visit(event.currentTarget.id)
    }

    groupClicked(event) {
        console.log("groupClicked", event)
        // remove active class from all items
        // add active class to clicked item
        //event.currentTarget.classList.add("active")
        //Turbo.visit(event.currentTarget.id)
    }
}
