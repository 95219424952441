import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="transcript-audio-player"
export default class extends Controller {
  static targets = [ "audio", "segment" ]
  connect() {
  }

  itemClicked(ev) {
    const segment = ev.currentTarget
    const startTime = segment.dataset.itemDataOffsetsFrom / 1000.0
    const endTime = segment.dataset.itemDataOffsetsTo / 1000.0
    this.audioTarget.currentTime = startTime
    this.audioTarget.play()
    //console.log(startTime, endTime)
  }
  updateTranscript(ev) {
    const currentTime = this.audioTarget.currentTime
   // console.log(ev.currentTarget.currentTime, this.segmentTargets)
    let segmentTime = 0
    let segmentEndTime = 0
    this.segmentTargets.forEach((segment) => {
      segmentTime = (segment.dataset.itemDataOffsetsFrom / 1000.0)
      segmentEndTime = (segment.dataset.itemDataOffsetsTo / 1000.0)
      if (currentTime >= segmentTime && currentTime <= segmentEndTime) {
        segment.classList.remove("item-disabled")
        //console.log(currentTime, segmentTime, segment.dataset.itemDataOffsetsFrom)

        // scroll to segment
        segment.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
        //return true
      } else {
        segment.classList.add("item-disabled")
      }
    })
  }
}
