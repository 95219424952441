import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  connect() {
  }
  static targets = ["copy"]

  copyToClipboard() {
      if(this.copyTarget == null) {
        console.error('No copy target found');
        return;
      }
    navigator.clipboard.writeText(this.copyTarget.innerHTML)
        .then(() => {
          console.log('Text copied to clipboard');
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
        });
  }

}
