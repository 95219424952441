import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";

// Connects to data-controller="dalle-viewer"
export default class extends Controller {
  static targets = ["totalImages", "imageLabel", "imageState", "loadSpinner", "updatePromptForm", "prompt", "dragger", "browser", "viewer", "brand", "container", "thumb", "viewerimg", "header", "headerId", "regenButton", "deleteButton"];

  static values = { info: Object };

  static outlets = ["dalle-image", "dalle-prompt-inspector"]


  selectedImageData = null;


  promptClicked(event) {
    console.log("promptClicked", event)
    this.promptTarget.contentEditable = true;
    // select the text in the promptTarget
    const range = document.createRange();
    range.selectNodeContents(this.promptTarget);
    const sel = window.getSelection();
    //sel.removeAllRanges();
    //sel.addRange(range);

    this.updatePromptFormTarget.classList.add("show");

    // make promptTarget not editable when the user clicks away
    // and deselect the text

    this.promptTarget.addEventListener("blur", (event) => {
      this.promptTarget.contentEditable = false;
        sel.removeAllRanges();
    });
  }

  dallePromptInspectorOutletConnected(outlet, element) {
    console.log("dallePromptInspectorOutletConnected", outlet, element)
    outlet.promptTextTarget.innerHTML = this.promptTarget.innerHTML;
  }
  dalleImageOutletConnected(outlet, element) {
   // console.log("dalleImageOutletConnected", outlet, element)
    const isNewImage = outlet.isNewValue;
    const isExistingImage = !isNewImage;
    //console.log("dalleImageOutletConnect", this.selectedImageData)
    //find the image element in element and add the show class to the image
    // only if the image is already loaded
    this.selectedImageData = outlet.imageDataValue;

    const thumbClass = "thumb_browser_item";
    // remove the active class from all the thumbs
    this.thumbTargets.forEach((thumb) => {
        thumb.classList.remove("active");
    })
    const thumbId = "thumb_dalle_image_" + this.selectedImageData.id;
    const thumbElement = this.element.querySelector("#" + thumbId);
    if (thumbElement) {
      thumbElement.classList.add("active");
    }
    const img = element.querySelector("img");
    if (img.complete) {
      img.classList.add("show");
      this.loadSpinnerTarget.classList.remove("show");

    }

    if( isExistingImage ) {
      if(this.hasDallePromptInspectorOutlet){
        this.dallePromptInspectorOutlet.promptTextTarget.innerHTML = this.selectedImageData.prompt;
        // this.dallePromptInspectorOutlet.promptTextTarget.innerHTML = this.selectedImageData.prompt;
      }
      // this.dallePromptInspectorOutlet.promptTextValue = this.selectedImageData.prompt;
      this.scrollToActive();
      this.regenButtonTarget.classList.remove("disabled");
      this.deleteButtonTarget.classList.remove("disabled");

      if (this.selectedImageData.label == null || this.selectedImageData.label == ""){
        this.selectedImageData.label = "Untitled";
      }
      this.headerIdTarget.innerHTML = this.selectedImageData.id + " - " + this.selectedImageData.label;
      this.imageStateTarget.innerHTML = this.selectedImageData.state;
      //this.imageLabelTarget.innerHTML = this.selectedImageData.label;
      let pluralized_version = this.selectedImageData.total_images > 1 ? "Versions" : "Version";
      this.totalImagesTarget.innerHTML = this.selectedImageData.total_images + " " + pluralized_version;

    } else {
      this.headerIdTarget.innerHTML = "New";
      this.regenButtonTarget.classList.add("d-none");
      this.deleteButtonTarget.classList.add("d-none");
    }

    if( this.selectedImageData.state == "pending" || this.selectedImageData.state == "queued"  ) {
      this.showGeneratingUi();
    }
    // set the browser height from local storage
    const browserHeight = localStorage.getItem("browserHeight");
    this.browserTarget.style.height = `${browserHeight}px`;

  }

  scrollToActive() {
    const activeElement = this.element.querySelector('.active')
    if (activeElement) {
      activeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }


  draggerOffset = 0;
  connect() {
    //console.log("dalle_viewer_controller connected", this.dalleImageOutlet)
    const browserHeight = localStorage.getItem("browserHeight");
    this.browserTarget.style.height = `${browserHeight}px`;
    this.browserTarget.classList.add("show");
    this.element.classList.add("show");

    this.viewerModes = ["browser", "viewer", "both"];

    this.viewerMode = "both";
    this.updateViewerToCurrentMode();
  }

  tabDown(event) {
    // cycle through the viewer modes

    event.preventDefault();
    console.log("tabDown", event)
    // toggle visibility of the browser, viewer, and both at once
    this.goToNextViewerMode();
    this.updateViewerToCurrentMode();

  }

  goToNextViewerMode() {
    console.log("goToNextViewerMode", this.viewerMode, this.viewerModes)

    const currentIndex = this.viewerModes.indexOf(this.viewerMode);
    const nextIndex = (currentIndex + 1) % this.viewerModes.length;
    this.viewerMode = this.viewerModes[nextIndex];
    console.log("goToNextViewerMode", this.viewerMode)

  }

  updateViewerToCurrentMode() {
    console.log("updateViewerToCurrentMode", this.viewerMode)
    this.element.classList.remove("browser", "viewer", "both");
    this.element.classList.add(this.viewerMode);
  }


  showGeneratingUi(){
    this.dalleImageOutletElement.classList.add("loading");

    // create a new loader element

    const loaderElement = document.createElement("div")
    loaderElement.classList.add("img_loader");
    const id_string = `wait_ticks_dalle_image_${this.selectedImageData.id}`;
    loaderElement.innerHTML = `<span>Generating: </span><span id=${id_string}>0</span>`;

    // find the element with the class .image_wrap in the outlet element
    const imageWrap = this.dalleImageOutletElement.querySelector(".image_wrap");
    imageWrap.appendChild(loaderElement);

  }

  regenerateClicked(event) {
    event.preventDefault();
    this.regenButtonTarget.classList.add("disabled");
    this.deleteButtonTarget.classList.add("disabled");

    // disable the button
    //event.target.disabled = true;
    //event.target.classList.add("disabled");
    console.log("regenerateClicked", this.selectedImageData)
    this.showGeneratingUi();


    fetch("/dalle_images/" + this.selectedImageData.id + "/regenerate")

  }
  deleteClicked(event) {
    event.preventDefault();
    console.log("headerDeleteClicked", event)

    let url = "/dalle_images/" + this.selectedImageData.id
    const request = new FetchRequest('DELETE', url, { responseKind: 'turbo-stream' })
    request.perform()
  }
  viewerimgLoaded(event) {
    //console.log("viewerimgLoaded", event)
    event.target.classList.add("show");
    console.log(this.dalleImageOutlet)
    this.loadSpinnerTarget.classList.remove("show");
  }
  viewerimgTargetConnected(element) {
    //console.log("viewerImgConnected", element)
    //element.classList.add("show");
  }
  thumbTargetConnected(element) {
    //console.log("thumbTargetConnected", element)
    element.classList.add("show");
  }
  thumbClicked(event) {
    console.log("thumbClicked", event)
    if(event.params.imageData.id == this.selectedImageData.id) {
        return;
    }
    this.selectedImageData = event.params.imageData;
    //console.log(event.delegateTarget)
    // remove the active class from all the thumbs
    this.thumbTargets.forEach((thumb) => {
        thumb.classList.remove("active");
    })

    event.currentTarget.classList.add("active");
  }
  imageLoadError(event) {
    console.log("imageLoadError", event)
    // remove the image
    //event.target.parentElement.remove();
   // event.target.remove();
  }
  imageLoaded(event) {
  // console.log("imageLoaded", event)
    event.target.classList.add("show");
  }
  startResize(event) {
    //console.log("startResize")
    this.draggerOffset = this.draggerTarget.getBoundingClientRect().bottom - event.clientY;

    event.preventDefault();
    this.isResizing = true;
    document.addEventListener("mousemove", this.performResize);
    document.addEventListener("mouseup", this.stopResize);
  }

  performResize = (event) => {
    if (this.isResizing) {
      const rect = this.containerTarget.getBoundingClientRect();
      const totalHeight = rect.height;
      const top = rect.top;
      const cursorPos = event.clientY - top;
      let newHeight = (totalHeight - cursorPos) - this.draggerOffset;
      // max height is 512, min height is 128
      newHeight = Math.min(Math.max(newHeight, 128), 512);
      this.browserTarget.style.height = `${newHeight}px`;

      // store the new height in local storage
      localStorage.setItem("browserHeight", newHeight);
    }
  };

    stopResize = () => {
        this.isResizing = false;
        document.removeEventListener("mousemove", this.performResize);
        document.removeEventListener("mouseup", this.stopResize);
    };

  updatePromptClicked(event) {
    event.preventDefault();
    console.log("updatePromptClicked", this.promptTarget.innerHTML)
    //this.updatePromptFormTarget.classList.remove("show");
    this.promptTarget.contentEditable = false;

    // send the new prompt to the server
    const url = "/dalle_images"
    const prompt = this.promptTarget.innerHTML;
    const request = new FetchRequest('POST', url, { responseKind: 'turbo-stream', body: { prompt: prompt } })
    request.perform()
  }

}
