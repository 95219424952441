import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-replace"
export default class extends Controller {
  static values = { url: String }

  connect() {
    this.element.addEventListener('turbo:load', this.replace.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('turbo:load', this.replace.bind(this))
  }

  replace(event) {

    this.element.src = this.urlValue
    console.log("Image replaced", this.element)
  }
}
