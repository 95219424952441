import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "dragger", "sidebar", "viewport", "brand", "inspectorDragger", "inspector"];
  static outlets = ["ui-state-saver"];

  static keyActionMap = {
    'i': 'toggleInspector',
    'b': 'toggleSidebar',

    // Add more keys and actions here as needed
  };

  keydown(event) {
    console.log(event.target.tagName.toLowerCase())
    if (event.target.tagName.toLowerCase() === 'textarea') {
      return;
    }
    if (event.target.tagName.toLowerCase() === 'input') {
      return;
    }

    const action = this.constructor.keyActionMap[event.key];
    if (action) {
      this[action](event);
    }
  }

  connect() {
    // Load saved width from localStorage
    const savedWidth = localStorage.getItem("sidebarWidth");
    const savedInspectorWidth = localStorage.getItem("inspectorWidth");
    if (savedWidth) {
      this.sidebarTarget.style.width = `${savedWidth}px`;
      this.resizeHeader();
    }
    if (savedInspectorWidth) {
      this.inspectorTarget.style.width = `${savedInspectorWidth}px`;
    }
    const inspectorVisible = localStorage.getItem("inspectorVisible");
    console.log("inspectorVisible",typeof inspectorVisible)
      this.inspectorTarget.style.display = inspectorVisible === 'true'  ? 'block' : 'none';
      this.inspectorDraggerTarget.style.display = inspectorVisible === 'true' ? 'block' : 'none';

  }

  uiStateSaverOutletConnected(outlet, element) {
    console.log("uiStateSaverOutletConnected", outlet, element)
  }

  // browser sidebar management

  toggleSidebar() {
    const sidebar = this.sidebarTarget;
    const visible = sidebar.style.display === 'block';
    sidebar.style.display = visible ? 'none' : 'block';
    this.draggerTarget.style.display = visible ? 'none' : 'block';
  }

  startResize(event) {
    event.preventDefault();
    this.isResizing = true;
    document.addEventListener("mousemove", this.performResize);
    document.addEventListener("mouseup", this.stopResize);
  }



  performResize = (event) => {
    if (this.isResizing) {
      const newWidth = event.clientX - this.sidebarTarget.getBoundingClientRect().left;
      this.sidebarTarget.style.width = `${newWidth}px`;
      localStorage.setItem("sidebarWidth", newWidth);
      this.resizeHeader();
    }
  };


  resizeHeader(){
    const parentLeft = this.sidebarTarget.parentElement.getBoundingClientRect().left;
    const rect = this.draggerTarget.getBoundingClientRect();
    const halfWidth = rect.width / 2;
    const newWidth2 = rect.right - parentLeft - halfWidth;
    this.brandTarget.style.width = `${newWidth2}px`;
  }

  stopResize = () => {
    this.isResizing = false;
    document.removeEventListener("mousemove", this.performResize);
    document.removeEventListener("mouseup", this.stopResize);
    this.uiStateSaverOutlet.saveUiState("sidebarWidth", localStorage.getItem("sidebarWidth"));
  };

  // inspector management
  toggleInspector() {
    const inspector = this.inspectorTarget;
    const visible = inspector.style.display === 'block';
    inspector.style.display = visible ? 'none' : 'block';
    this.inspectorDraggerTarget.style.display = visible ? 'none' : 'block';

    const newState = !visible;
    localStorage.setItem("inspectorVisible", newState);
    this.uiStateSaverOutlet.saveUiState("inspectorVisible", localStorage.getItem("inspectorVisible"));

    const inspectorVisible = localStorage.getItem("inspectorVisible");
    console.log("inspectorVisible", inspectorVisible)

  }
  startInspectorResize(event) {
    event.preventDefault();
    this.isInspectorResizing = true;
    document.addEventListener("mousemove", this.performInspectorResize);
    document.addEventListener("mouseup", this.stopInspectorResize);
  }


  performInspectorResize = (event) => {
    if (this.isInspectorResizing) {
      const viewportWidth = this.containerTarget.getBoundingClientRect().width;
      const newWidth = viewportWidth - event.clientX;
      this.inspectorTarget.style.width = `${newWidth}px`;
      localStorage.setItem("inspectorWidth", newWidth);
    }
  };


  stopInspectorResize = () => {
    this.isInspectorResizing = false;
    document.removeEventListener("mousemove", this.performInspectorResize);
    document.removeEventListener("mouseup", this.stopInspectorResize);
    this.uiStateSaverOutlet.saveUiState("inspectorWidth", localStorage.getItem("inspectorWidth"));
  };

}