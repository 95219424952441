import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["chatform", "content", "fieldset", "messages", "chatbody"]

    connect() {
        console.log("Hello, Chast!", this.element)
        this.contentTarget.focus()

        document.addEventListener('turbo:before-stream-render', (event) => {
            // Assuming the target name you want to match is in the ID attribute
            if (event.target.target.startsWith('content_chatbot_message_')) {
                this.scrollToBottom();
            } else if (event.target.target.startsWith('thread_message_thread_')) {
                this.scrollToBottom();
            } else {
                console.log("turbo:before-stream-render not messagesTarget", event.target);
                window.tg = event.target;
            }
        });
    }

    contentTargetConnected() {
        console.log("contentTargetConnected!", this.element)
        this.contentTarget.focus()
        setTimeout(() => {
            this.contentTarget.focus()
        }, 10);
    }

    scrollToBottom() {
        this.chatbodyTarget.scrollTo(0, this.chatbodyTarget.scrollHeight);
    }

    submitForm(event) {
        event.preventDefault()
        this.scrollToBottom()
        const formData = new FormData(this.chatformTarget)
        const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

        this.fieldsetTarget.disabled = true; // Disable the fieldset
        this.contentTarget.value = "Pondering..."; // Clear the content field

        fetch(this.chatformTarget.action, {
            method: "POST",
            headers: {
                'X-CSRF-Token': token
            },
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                console.log("response 1")
                this.scrollToBottom()
                this.contentTarget.value = "";
                return response.json();
            })
            .then(data => {
                console.log("response 2", data);
                this.fieldsetTarget.disabled = false;
                this.contentTarget.focus()
                this.scrollToBottom()
            })
            .catch((error) => {
                console.error("response 3", 'Error:', error);
                this.fieldsetTarget.disabled = false;
                this.contentTarget.focus()
            });

    }
}
