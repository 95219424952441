import {Controller} from "@hotwired/stimulus"


// Connects to data-controller="ai-assistant-thread"
export default class extends Controller {

    static targets = ["newMessageForm", "newMessageField"]

    connect() {
        console.log("Hello, AI Assistant Thread!")
        console.log(this.newMessageFormTarget)
        window.threadController = this
        //this.newMessageFormTarget.addEventListener("submit", this.sendMessage)
        //console.log(this.newMessageFormTarget)

    }

    newMessage(event) {
        console.log(event)
        //this.newMessageFieldTarget.value = ""
        //this.newMessageFieldTarget.disabled = true
        //event.preventDefault()
    }

    sendMessage(event) {
        console.log(this)
        event.preventDefault()
        let formData = new FormData(this)
        console.log(formData)
    }
}
