import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="controller"
export default class extends Controller {
  connect() {
  }

  teststream() {
    fetch('/openai_requests/teststreaming')
  }
}
